import { Type } from "class-transformer"
import { BaseSchema } from "./BaseSchema"

export class ShipmentTrackingSchema extends BaseSchema {
  @Type(() => String)
  srNumber: string

  destination: {
    city: string
    country: string
    region: string
  }

  @Type(() => Date)
  shipmentDate: Date

  @Type(() => Date)
  carrierEta: Date

  @Type(() => Number)
  piecesTotal: number

  @Type(() => Number)
  piecesDeliveredTotal: number
}