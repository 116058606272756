import { Singleton } from 'typescript-ioc'
import { ShipmentTrackingSchema} from '../../Schema/ShipmentTrackingSchema'
import { plainToClass, Type } from 'class-transformer'
import { ShipmentTrackingAlertSchema } from '../../Schema/ShipmentTrackingAlertSchema'
import { ServiceLocator } from '../ServiceLocator'
import { AxiosClientFactory } from '../AxiosClientFactory'
import { APIClientBase } from './Clients/APIClientBase'

export class ShipmentTrackingResponse {
  @Type(() => ShipmentTrackingSchema)
  shipment: ShipmentTrackingSchema

  @Type(() => ShipmentTrackingAlertSchema)
  alerts: ShipmentTrackingAlertSchema[]

}

@Singleton
export class ShipmentTrackingAPI {

  api: APIClientBase

  constructor () {
    this.api = ServiceLocator.resolve(AxiosClientFactory).createClient('shipmentTracking')
  }

  public async getTrackingDetails (srNumber: string) {
    return this.api.post<ShipmentTrackingResponse[]>('/v1/shipment-alerts', { srNumber }).then(response => {
      return plainToClass(ShipmentTrackingResponse, response.data )
    })
  }
}
