import packageJson from '../package.json'

const Configs = {
  appVersion: packageJson.version,
  recaptchaClientKey: process.env.REACT_APP_RECAPTCHA_CLIENT_KEY,
  apis: {
    shipmentTracking: process.env.REACT_APP_API_TRACKING,
  },
}

export default Configs
