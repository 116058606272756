import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { AppLayout } from "./Layout/AppLayout";
import { Theme } from "./Theme";


function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
   </ThemeProvider>
  );
}

export default App;
