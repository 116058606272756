import React from 'react'

import Logo from '../assets/logo.png'

interface AppLogoProps {
  className?: any
}

export const AppLogo: React.FC<AppLogoProps> = ({
  className,
}) => {
  return <img className={className} src={Logo} alt={'Allstar Delivery'} />
}
