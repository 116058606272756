import React, { useMemo } from 'react'
import { format as dateFnsFormat } from 'date-fns'
import { ListItem, ListItemText } from '@material-ui/core'

import { ShipmentTrackingAlertSchema, ShipmentTrackingAlertStage } from '../../../Schema/ShipmentTrackingAlertSchema'
import { Alert } from '@material-ui/lab'

interface ShipmentTrackingAlertProps {
  alert: ShipmentTrackingAlertSchema
}

export const ShipmentTrackingAlert: React.FC<ShipmentTrackingAlertProps> = ({
  alert,
}) => {
  const severity = useMemo(() => {
    switch (alert?.stage) {
      case ShipmentTrackingAlertStage.Delay:
        return 'warning'
      case ShipmentTrackingAlertStage.Delivered:
        return 'success'
      default:
        return 'info'
    }
  }, [alert])

  const message = useMemo(() => {
    return alert?.stage === ShipmentTrackingAlertStage.Delay ? 'Delay: ' + alert?.message : alert?.message
  }, [alert])

  return (
    <ListItem>
      <ListItemText
        primary={(
          <Alert style={{
            fontSize: 14,
          }} severity={severity}>{message}</Alert>
        )}
        secondary={alert?.stage !== ShipmentTrackingAlertStage.Delivered && alert?.published && dateFnsFormat(alert?.published, 'PPPPp')}
      />
    </ListItem>
  )
}