import { Box, Button, CircularProgress, Container, createStyles, Grid, makeStyles, TextField, useTheme } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ShipmentTrackingResponse } from '../../Services/API/ShipmentTrackingAPI'
import { useGetShipmentTrackingDetails } from './Hooks/useGetShipmentTrackingDetails'
import _ from 'lodash'
import { AppLogo } from '../../Components/AppLogo'
import { ShipmentTrackingListItem } from './Components/ShipmentTrackingListItem'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Search } from '@material-ui/icons'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)?.get('q')
}

export const ShipmentTracking: React.FC = () => {
  const theme = useTheme()
  const styles = useStyles()
  const appStyles = useAppStyles()
  const history = useHistory()
  const query = useQuery()

  const [trackingNumber, setTrackingNumber] = useState<string>()
  const [trackingResults, setTrackingResult] = useState<ShipmentTrackingResponse[]>()

  const { getShipmentTrackingDetails, processing, errorMessage } = useGetShipmentTrackingDetails()

  const loadData = useCallback((trackingNumber: string) => {
    getShipmentTrackingDetails(trackingNumber).then(response => {
      setTrackingResult(response)
    }).catch(err => {
      setTrackingResult(null)
    })
  }, [getShipmentTrackingDetails])

  useEffect(() => {
    //load data on the initial component laod if the query data is present
    if (query) {
      setTrackingNumber(query)
      loadData(query)
    }
  }, [])

  const handleOnFind = () => {
    history.push('/shipment-tracking?q=' + escape(trackingNumber))
    loadData(trackingNumber)
  }

  const handleOnKeyUp = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (_.includes(['Enter'], event?.key)) {
      loadData(trackingNumber)
    }
  }, [trackingNumber, loadData])

  return (
    <Container maxWidth={'md'}>
      <Box key={'logo'} className={styles.logoContainer}>
        <AppLogo className={styles.logo}/>
      </Box>
      <Grid container style={{alignItems: 'center', marginBottom: theme.spacing(1)}} spacing={2}>
        <TextField
          value={trackingNumber || null}
          disabled={processing}
          label={'Tracking Number'}
          className={appStyles.flexOne}
          onChange={event => setTrackingNumber(event.target.value)}
          helperText={'Enter one or more tracking numbers comma separated.'}
          onKeyUp={handleOnKeyUp}
        />
        <Grid item>
          <Button
            disabled={processing}
            onClick={handleOnFind}
            color={'primary'}
            variant={'contained'}
            startIcon={(
              <Search />
            )}
        >
          Find
        </Button>
        </Grid>
      </Grid>
      <Box key={'results'} className={appStyles.content}>
        {!processing && errorMessage && <Alert key={'error'} severity={'error'}>{errorMessage}</Alert>}
        {!processing && trackingResults && trackingResults.length === 0 && <Alert key={'warning'} severity={'warning'}>The shipment(s) tracking information is not located.</Alert>}
        {processing && <CircularProgress />}
        {_.map(trackingResults, shipmentDetails => (
          <>
            <ShipmentTrackingListItem key={shipmentDetails.shipment.srNumber} shipmentDetails={shipmentDetails} />
          </>
        ))}
      </Box>
    </Container>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
    },
    container: {
      paddingTop: theme.spacing(4),
      padding: theme.spacing(2),
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
    logo: {
      width: '100%',
      maxWidth: 140,
      height: 'auto',
    },
  }),
)