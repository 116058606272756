import { Type } from "class-transformer"
import { BaseSchema } from "./BaseSchema"

export enum ShipmentTrackingAlertStage {
  Receiving = 'RECEIVING',
  Packing = 'PACKING',
  Shipping = 'SHIPPING',
  Transit = 'TRANSIT',
  Delivered = 'DELIVERED',
  Delay = 'DELAY',
}

export class ShipmentTrackingAlertSchema extends BaseSchema {
  @Type(() => String)
  message: string

  @Type(() => Date)
  published: Date

  @Type(() => String)
  stage: ShipmentTrackingAlertStage
}