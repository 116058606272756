
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Configs from '../Configs'
import { Paper } from '@material-ui/core'
import { ShipmentTracking } from '../App/ShipmentTracking'
import { useAppStyles } from '../Hooks/useAppStyles'

export const AppLayout: React.FC = () => {
  const classes = useAppStyles()

  return (
    <>
        <Switch>
          <Redirect path={'/'} to={'/shipment-tracking'} exact/>
          <Route path={'/shipment-tracking'} component={ShipmentTracking} />
        </Switch>
      <Paper className={classes.version}>{Configs.appVersion}</Paper>
    </>
  )
}
