import { Avatar, Box, Card, CardContent, colors, Divider, Grid, Typography } from '@material-ui/core'
import { Done as DoneIcon } from '@material-ui/icons'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { format as dateFnsFormat } from 'date-fns'

import { ShipmentTrackingResponse } from '../../../Services/API/ShipmentTrackingAPI'
import { useAppStyles } from '../../../Hooks/useAppStyles'
import { ExandMoreButton } from '../../../Components/ExpandMoreButton'
import { ShipmentTrackingAlert } from './ShipmentTrackingAlert'

interface ShipmentTrackingListItemProps {
  shipmentDetails: ShipmentTrackingResponse
}

export const ShipmentTrackingListItem: React.FC<ShipmentTrackingListItemProps> = ({
  shipmentDetails,
}) => {
  const appStyles = useAppStyles()

  const [showMore, setShowMore] = useState(false)

  const destination = useMemo(() => {
    return `${shipmentDetails?.shipment?.destination?.city}, ${shipmentDetails?.shipment?.destination?.country}`
  }, [shipmentDetails])

  const carrierEta = useMemo(() => {
    if (_.isDate(shipmentDetails?.shipment?.carrierEta)) {
      return dateFnsFormat(shipmentDetails?.shipment?.carrierEta, 'PPPP')
    }
  }, [shipmentDetails])

  const isDelivered = useMemo(() => {
    // if any of the items is delivered, consider delivered
    return shipmentDetails?.shipment?.piecesDeliveredTotal > 0
  }, [shipmentDetails?.shipment?.piecesDeliveredTotal])

  const statusColor = useMemo(() => {
    return isDelivered ? colors.lightGreen[400] : undefined
  }, [isDelivered])

  const lastAlert = useMemo(() => {
    return _.last(shipmentDetails?.alerts)
  }, [shipmentDetails?.alerts])

  return (
    <>
        {shipmentDetails?.shipment && (
        <Card className={appStyles.contentItem}>
          <CardContent>
            <Grid container spacing={1} wrap={'nowrap'}>
              <Grid item>
                <Avatar style={{background: statusColor}}><DoneIcon /></Avatar>
              </Grid>
              <Grid item style={{flex: 1}}>
                <Typography>Tracking Number: {shipmentDetails?.shipment?.srNumber }</Typography>
                <Typography>Destination: {destination}</Typography>
                <Typography>Carrier ETA: {carrierEta}</Typography>
                <Divider />
                <Box display={lastAlert && !showMore ? undefined : 'none'} marginTop={  1}>
                  <Typography>Last Alert</Typography>
                  <ShipmentTrackingAlert alert={lastAlert} />
                  <Typography variant={'body2'}>{shipmentDetails?.alerts?.length || 0} Alert(s)</Typography>
                </Box>

                <Box display={showMore ? undefined : 'none'}>
                {shipmentDetails?.alerts && (
                  <>
                  {_.map(shipmentDetails?.alerts, alert => (
                    <>
                      <ShipmentTrackingAlert key={alert?.published?.getTime()} alert={alert} />
                      <Divider />
                    </>
                  ))}
                  </>
                )}
                </Box>
              </Grid>
              <Grid item>
                <ExandMoreButton onChange={setShowMore} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        )}
      </>
  )
}