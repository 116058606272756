import { IconButton } from '@material-ui/core'
import { ExpandMore, ExpandLess} from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

interface ExandMoreButtonProps {
  expanded?: boolean
  onChange?: (expanded: boolean) => void
}

export const ExandMoreButton: React.FC<ExandMoreButtonProps> = ({
  expanded: expandedProp = false,
  onChange = (expanded: boolean) => {}
}) => {
  const [expanded, setExpanded] = useState(expandedProp)

  useEffect(() => {
    onChange(expanded)
  }, [expanded, onChange])

  const handleOnClick = () => {
    setExpanded(prevState => !prevState);
  }

  const ButtonIcon = expanded ? ExpandLess : ExpandMore

  return (
    <IconButton onClick={handleOnClick}><ButtonIcon /></IconButton>
  )
}