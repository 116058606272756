import axios from 'axios'
import _ from 'lodash'
import { Singleton } from 'typescript-ioc'
import Configs from '../Configs'
//import mocks from '../Mocks/Mocks'

@Singleton
export class AxiosClientFactory {
  /**
   * Axios client registery
   */
  private clients: any = {}

  /**
   * Access Token
   */
  private accessToken: string = null

  /**
   * Refresh Token
   */
  private refreshToken: string = null

  /**
   * Initialize clients
   */
  constructor () {
    this.clients = {}
  }

  public getClient (apiName: string) {
    if (_.has(this.clients, apiName)) {
      return this.clients[apiName]
    }
    return null
  }

  public createClient (apiName: string) {
    const endpoint = _.get(Configs.apis, apiName)
    if (!endpoint) {
      throw Error(`Invalid API Name(${apiName}).`)
    }
    if (_.has(this.clients, apiName)) {
      return this.clients[apiName]
    }
    const client = axios.create({
      baseURL: endpoint,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        AppVersion: Configs.appVersion,
      },
    })
    this._setClientAuthToken(client)
    this.clients[apiName] = client
    return client
  }

  /**
   * Update access token for all clients
   * @param accessToken
   */
  public setAuthToken (accessToken: string) {
    this.accessToken = accessToken
    _.forEach(this.clients, (client) => {
      this._setClientAuthToken(client)
    })
  }

  /**
   * Set access token for a axios client
   * @param client
   */
  private _setClientAuthToken (client: any) {
    if (client) {
      client.defaults.headers.common.Authorization = ''
      delete client.defaults.headers.common.Authorization
      client.defaults.headers.common.Authorization = `Bearer ${this.accessToken}`
    }
  }
}
